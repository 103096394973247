//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.collapsable
{
    width:     100%;
    max-width: 500px;
    position:  relative;

    .collapsableTitle
    {
        position:         relative;
        z-index:          1;
        cursor:           pointer;
        margin:           0 10px;
        padding:          20px 10px;
        box-shadow:       0 2px 7px 0 $black25;
        user-select:      none;
        border-radius:    10px;
        background-color: $white;

        @include scaleTransformEffect(1.05, 1.1);

        h2
        {
            color:       $orientBlue;
            text-align:  center;
            font-weight: bold;
        }
    }

    .collapsableContentContainer
    {
        overflow:      hidden;
        max-height:    0;
        margin-bottom: 0;
        padding:       0 10px 0 10px;
        transition:    max-height .2s linear,
                       margin .2s linear,
                       padding .2s linear;

        &::before
        {
            content:          '';
            position:         absolute;
            top:              35px;
            left:             50%;
            width:            100vw;
            height:           calc(100% - 35px);
            transform:        translateX(-50%);
            max-height:       0;
            background-color: $white;
            transition:       max-height .2s linear;
        }

        &.collapsableContentVisible
        {
            padding-top:    30px;
            padding-bottom: 10px;
            margin-bottom:  35px;
            max-height:     500px;

            &::before
            {
                max-height: 500px;
            }
        }

        .collapsableContent
        {
            position: relative;
            z-index:  1;
        }
    }
}
