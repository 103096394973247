//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.notificationHandler
{
    position: absolute;

    > div
    {
        z-index: $zIndexNotification !important;
    }

    :global(.notification)
    {
        border-radius: 10px !important;
    }

    @media (max-width: $mobileBreakpoint)
    {
        :global(.notification)
        {
            max-width: 60vw;
        }

        :global(.notification-container)
        {
            left:            0;
            right:           0;
            overflow:        hidden;
            margin:          0 auto;
            display:         flex;
            justify-content: center;
        }

        :global(.notification-enter)
        {
            visibility: hidden;
            transform:  scale(0.5);
            opacity:    0;
        }

        :global(.notification-enter.notification-enter-active)
        {
            visibility: visible;
            transform:  scale(1);
            opacity:    1;
        }

        :global(.notification-exit)
        {
            visibility: visible;
            transform:  scale(1);
            opacity:    1;
        }

        :global(.notification-exit.notification-exit-active)
        {
            visibility: hidden;
            transform:  scale(0.5);
            opacity:    0;
        }
    }
}
