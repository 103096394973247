//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.overrideFailedHeader
{
    background:    $monzaRed;
    border:        2px solid $monzaRed;
    border-bottom: none;
    height:        70px;
}

.overrideFailedContent
{
    border:          2px solid $monzaRed;
    border-top:      none;
    border-radius:   0 0 0.5rem 0.5rem;
    display:         flex;
    flex-flow:       column;
    justify-content: center;
    align-items:     center;

    p
    {
        margin-top:  5px;
        font-weight: $fontWeightSemiBold;
    }
}

.overrideDraftHeader
{
    background:    $white;
    border:        2px solid $doveGray;
    border-bottom: none;

    *
    {
        color: $black;
    }
}

.overrideDraftContent
{
    height:          100%;
    border:          2px solid $doveGray;
    border-top:      none;
    border-radius:   0 0 0.5rem 0.5rem;
    display:         flex;
    flex-flow:       column;
    justify-content: space-between;
}

.overrideLoadingContent
{
    height:          100%;
    border:          2px solid $doveGray;
    border-radius:   0 0 0.5rem 0.5rem;
    text-align:      center;
    display:         flex;
    flex-flow:       column;
    justify-content: center;

    p:first-child
    {
        font-size:     20px;
        font-weight:   $fontWeightSemiBold;
        margin-bottom: 0;
    }
}
