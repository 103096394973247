//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.invoicesAndPlansScreen
{
    display:        flex;
    flex-direction: column;
    gap:            40px;
    width:          100%;
    max-width:      100%;
    max-height:     100%;
    min-width:      0;
    min-height:     0;

    .headerRow
    {
        width:           100%;
        display:         flex;
        justify-content: space-between;
        align-items:     center;
        margin-bottom:   20px;
        flex-direction:  column;
        gap:             20px;

        @media (min-width: $screen-md)
        {
            flex-direction: row;
        }

        h2
        {
            font-size:   24px;
            font-weight: $fontWeightBold;
            text-align:  center;
        }
    }

    .vatLabel
    {
        font-size:  14px;
        text-align: center;

        @media (min-width: $screen-md)
        {
            text-align: left;
        }
    }

    .managementContainer
    {
        display:               grid;
        grid-template-columns: 1fr;
        gap:                   20px;

        @media (min-width: $screen-sm)
        {
            grid-template-columns: 1fr 1fr;
        }

        .managementItem
        {
            display:        flex;
            flex-direction: column;
            background:     $lightSilver;
            border-radius:  14px;
            padding:        20px;
            gap:            14px;

            .title
            {
                font-size:     22px;
                font-weight:   $fontWeightBold;
                margin-bottom: 10px;
            }

            .ctaItem
            {
                border:        none;
                display:       block;
                background:    $regalBlue;
                width:         fit-content;
                font-size:     16px;
                border-radius: 10px;
                padding:       8px 16px;
                color:         white;
                font-weight:   $fontWeightBold;

                &:hover
                {
                    text-decoration: underline;
                }
            }
        }
    }
}
