@mixin hideScrollbar()
{
    -ms-overflow-style: none;
    scrollbar-width:    none;

    &::-webkit-scrollbar
    {
        display: none;
    }
}
