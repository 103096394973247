//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.text
{
    white-space: pre-wrap;

    &.small
    {
        font-size: 12pt;
    }

    &.middle
    {
        font-size: 15pt;
    }

    &.black
    {
        color: $black;
    }

    &.white
    {
        color: $white;
    }

    &.textCenter
    {
        text-align: center;
    }

    &.textLeft
    {
        text-align: left;
    }

    &.textRight
    {
        text-align: right;
    }
}
