//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.screen
{
    display:     flex;
    flex-flow:   row nowrap;
    align-items: flex-start;

    .contentWrapper
    {
        display:         flex;
        flex-flow:       column;
        align-items:     flex-start;
        justify-content: space-around;
        gap:             50px;
        padding:         50px 30px 30px 30px;
        width:           100%;
        max-width:       100%;
        max-height:      100%;
        height:          100%;
        min-width:       0;
        min-height:      0;

        @media (min-width: $screen-md)
        {
            flex-flow: row nowrap;
        }

        @media (min-width: $screen-lg)
        {
            gap:     70px;
            padding: 70px 70px 50px 70px;
        }

        @media (min-width: 1400px)
        {
            gap:     100px;
            padding: 100px 100px 50px 100px;
        }
    }
}
