//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import "../../../../styles/all";

.explanationCardWrapper
{
    display:        flex;
    flex-direction: column;
    position:       relative;
    background:     $blue;
    color:          $white;
    padding:        30px;
    border-radius:  10px;
    box-shadow:     0 0 30px 0 $black30;

    .explanationCardCloseButtonWrapper
    {
        position: absolute;
        top:      20px;
        right:    20px;
        cursor:   pointer;
        @include svgColor($white60);
    }

    .explanationCardContent
    {
        position:       relative;
        z-index:        1;
        margin-top:     20px;
        display:        flex;
        flex-direction: column;
        gap:            20px;

        h2
        {
            font-weight: bold;
        }

        p
        {
            font-size: 16px;
        }

        .explanationCardVideoWrapper
        {
            width:        80%;
            aspect-ratio: 16 / 9;

            iframe
            {
                border: none;
            }
        }
    }

    .explanationCardIconWrapper
    {
        position:       absolute;
        pointer-events: none;
        bottom:         0;
        right:          0;
        width:          250px;
        @include svgColor($white20);
    }
}
