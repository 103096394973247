//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.sideMenuButton
{
    display:     flex;
    align-items: baseline;
    gap:         15px;
    font-weight: bold;
    cursor:      pointer;
    transition:  scale 0.1s linear;

    .sideMenuButtonIcon
    {
        $size:      16px;

        width:      $size;
        height:     $size;
        min-width:  $size;
        color:      $white;
        transition: transform 0.1s linear;
    }
}

.sideMenuButton:hover
{
    .sideMenuButtonIcon
    {
        transform: translateX(5px);
    }
}

.sideMenuButton:active
{
    .sideMenuButtonIcon
    {
        transform: translateX(10px);
    }
}
