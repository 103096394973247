//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.timePickerControl
{
    color:           $white;
    font-family:     $fontSourceGeneralBody;
    border-radius:   12px;
    height:          52px;
    display:         flex;
    align-items:     center;
    justify-content: space-between;
    position:        relative;

    &:focus-visible
    {
        outline: none;
    }

    input[type="time"]
    {
        min-width: calc(100% - 20px);

        &::-webkit-inner-spin-button,
        &::-webkit-calendar-picker-indicator
        {
            display:    none;
            appearance: none;
        }

        @media (min-width: $mobileBreakpoint)
        {
            min-width: auto;
        }
    }

    > span
    {
        margin-left:  auto;
        margin-right: auto;
    }

    .indicatorContainer
    {
        padding-right: 24px;
        position:      absolute;
        right:         0;

        .wrappedIcon
        {
            width:     20px;
            transform: rotate(90deg);
            color:     $black;
        }
    }
}

.overlayContainer
{
    position:        absolute;
    z-index:         100;
    top:             0;
    left:            0;
    width:           100%;
    height:          100%;
    opacity:         0;
    pointer-events:  none;
    display:         flex;
    align-items:     center;
    justify-content: center;
    backdrop-filter: blur(10px);

    &.overlayContainerOpen
    {
        opacity:        1;
        pointer-events: all;
    }

    .overlayContainerInner
    {
        display:          flex;
        flex-flow:        column;
        align-items:      center;
        width:            370px;
        max-width:        100%;
        padding:          68px 14px;
        background-color: $white;
        box-shadow:       0 5px 10px rgb(0 0 0 / 20%);
        border-radius:    1em;

        .timePickerContainer
        {
            display:     flex;
            flex-flow:   row nowrap;
            font-family: $fontSourceGeneralBody;
            font-size:   40px;
            font-weight: bold;

            .timePickerInnerContainer
            {
                display:       flex;
                flex-flow:     column;
                margin-bottom: 60px;

                input
                {
                    border:        0;
                    font-family:   $fontSourceGeneralBody;
                    font-size:     40px;
                    font-weight:   bold;
                    box-shadow:    $black20 0 3px 6px;
                    text-align:    center;
                    width:         100px;
                    height:        66px;
                    margin-bottom: 10px;
                }

                span
                {
                    font-size:   12px;
                    font-weight: 300;
                }
            }

            .timePickerSeparator
            {
                margin: 0 10px;
            }
        }
    }
}

.buttonGroup
{
    gap:             30px;
    width:           100%;
    display:         flex;
    justify-content: space-between;
    padding:         1rem 2rem;
}

.button
{
    border:           none;
    color:            $white;
    font-weight:      bold;
    background-color: $doveGray;
    border-radius:    10px;
    padding:          13px;
    box-shadow:       0 5px 10px $black20;
}

.buttonSecondary
{
    background-color: $taraweraBlue;
}
