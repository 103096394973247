//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

/* stylelint-disable no-descending-specificity */

$primaryColor:  $blue;
$disabledColor: $doveGray;

.button
{
    display:          flex;
    flex-flow:        row nowrap;
    align-items:      center;
    justify-content:  center;
    max-width:        100%;
    overflow:         hidden;
    color:            $white;
    border:           1px solid $primaryColor;
    padding:          11px 16px;
    border-radius:    6px;
    background-color: $primaryColor;
    cursor:           pointer;
    transition:       color .2s linear,
                      border-color .2s linear,
                      background-color .2s linear;

    &.iconOnly
    {
        .iconContainer
        {
            padding: 0 !important;
        }
    }

    &[disabled]
    {
        cursor:           auto;
        color:            $white !important;
        border-color:     $disabledColor !important;
        background-color: $disabledColor !important;
        pointer-events:   none;

        &.outline,
        &.outlineIcon
        {
            color:            $disabledColor !important;
            background-color: transparent !important;

            .iconContainer
            {
                svg
                {
                    @include svgColor($disabledColor, true);
                }
            }
        }

        .iconContainer
        {
            svg
            {
                @include svgColor($white, true);
            }

            &::before
            {
                border-color: $disabledColor !important;
            }
        }
    }

    p
    {
        font-size:   12px;
        font-weight: $fontWeightMedium;
        white-space: pre-wrap;
    }

    &:hover
    {
        color:            $primaryColor;
        background-color: $white;

        .iconContainer
        {
            svg
            {
                @include svgColor($primaryColor);
            }
        }
    }

    &.outline
    {
        color:            $primaryColor;
        background-color: transparent;

        &:hover
        {
            color:            $white;
            background-color: $primaryColor;

            .iconContainer
            {
                svg
                {
                    @include svgColor($white);
                }
            }
        }

        .iconContainer
        {
            svg
            {
                @include svgColor($primaryColor);
            }
        }
    }

    &.outlineIcon
    {
        border:           none;
        gap:              15px;
        color:            $primaryColor;
        background-color: transparent;

        p
        {
            font-size:   14px;
            font-weight: $fontWeightRegular;
        }

        &:hover
        {
            color:            $primaryColor;
            background-color: transparent;

            .iconContainer
            {
                &::before
                {
                    background-color: $primaryColor;
                }

                svg
                {
                    @include svgColor($white);
                }
            }
        }

        .iconContainer
        {
            position: relative;

            &.iconContainerRight
            {
                &::before
                {
                    left: 2px;
                }
            }

            &::before
            {
                content:       '';
                position:      absolute;
                top:           -8px;
                left:          -11px;
                height:        calc(100% + 14px);
                aspect-ratio:  1/1;
                border-radius: 6px;
                border:        1px solid $primaryColor;
                transition:    background-color .2s linear;
            }

            svg
            {
                @include svgColor($primaryColor);
            }
        }
    }

    &.white
    {
        color:            $black;
        border-color:     $white;
        background-color: $white;

        &:hover
        {
            color:            $white;
            border-color:     $primaryColor;
            background-color: $primaryColor;

            .iconContainer
            {
                svg
                {
                    @include svgColor($white);
                }
            }
        }

        &.outline
        {
            color:            $white;
            background-color: transparent;

            &:hover
            {
                border-color:     $primaryColor;
                background-color: $primaryColor;
            }

            .iconContainer
            {
                svg
                {
                    @include svgColor($white);
                }
            }
        }

        .iconContainer
        {
            svg
            {
                @include svgColor($black);
            }
        }
    }

    &.grey
    {
        border-color:     $doveGray;
        background-color: $doveGray;

        &:hover
        {
            color:            $white;
            border-color:     $primaryColor;
            background-color: $primaryColor;

            .iconContainer
            {
                svg
                {
                    @include svgColor($white);
                }
            }
        }

        &.outline
        {
            color:            $doveGray;
            background-color: transparent;

            &:hover
            {
                color:            $white;
                border-color:     $primaryColor;
                background-color: $primaryColor;

                .iconContainer
                {
                    svg
                    {
                        @include svgColor($white);
                    }
                }
            }

            .iconContainer
            {
                svg
                {
                    @include svgColor($doveGray);
                }
            }
        }

        .iconContainer
        {
            svg
            {
                @include svgColor($white);
            }
        }
    }

    &.link
    {
        color:       $doveGray;
        border:      0;
        padding:     0;
        font-weight: $fontWeightRegular;
        background:  none;
    }

    .iconContainer
    {
        width: 24px;

        &.iconContainerLeft
        {
            padding-right: 12px;
        }

        &.iconContainerRight
        {
            padding-left: 12px;
        }

        svg
        {
            @include svgColor($white);

            > *
            {
                transition: fill .2s linear,
                            stroke .2s linear;
            }
        }
    }
}
