//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../colors';

/* stylelint-disable */
@mixin placeholderColor($color)
{
    ::input-placeholder,
    &::-webkit-input-placeholder
    {
        color: $color;
    }

    ::placeholder,
    &::-moz-placeholder
    {
        color: $color;
    }

    ::input-placeholder,
    &::-ms-input-placeholder
    {
        color: $color;
    }

    ::placeholder,
    &::placeholder
    {
        color: $color;
    }

    :placeholder,
    &:-moz-placeholder
    {
        color: $color;
    }

    :input-placeholder,
    &:-ms-input-placeholder
    {
        color: $color;
    }
}

@mixin hidePlaceholder()
{
    ::input-placeholder,
    &::-webkit-input-placeholder
    {
        color: transparent;
    }

    ::placeholder,
    &::-moz-placeholder
    {
        color: transparent;
    }

    ::input-placeholder,
    &::-ms-input-placeholder
    {
        color: transparent;
    }

    ::placeholder,
    &::placeholder
    {
        color: transparent;
    }
}
/* stylelint-enable */
