//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.container
{
    box-shadow:    0 5px 10px $black20;
    border-radius: 10px;

    &:focus-visible
    {
        outline: none;
    }
}

.calendar
{
    width:     100%;
    max-width: unset;
}

.calendarToday
{
    background-color: $white;
    border:           1px solid $primaryColor;
    border-radius:    15px;

    &::after
    {
        display: none !important;
    }
}

.buttonGroup
{
    display:         flex;
    justify-content: space-between;
    padding:         1rem 2rem;
}

.button
{
    border:           none;
    color:            $white;
    font-weight:      bold;
    background-color: $doveGray;
    border-radius:    10px;
    padding:          13px;
    box-shadow:       0 5px 10px $black20;
}

.buttonPrimary
{
    background-color: $darkRed;
}

.buttonSecondary
{
    background-color: $taraweraBlue;
}

:global
{
    .Calendar__day
    {
        font-weight:   bold;
        padding:       0;
        margin-bottom: 0;
        border-radius: 15px;
    }

    .Calendar__day.-selected
    {
        background-color: $primaryColor;
        color:            $white;
        border-radius:    15px;
    }

    .Calendar__day.-weekend
    {
        color: $darkRed;
    }

    .Calendar__weekDay
    {
        color: $black;
    }

    .Calendar__monthArrowWrapper.-left,
    .Calendar__monthArrowWrapper.-right
    {
        padding:       6px;
        border-radius: 10px;
        box-shadow:    3px 0 6px $black20;
    }

    .Calendar__monthArrowWrapper.-right
    {
        box-shadow: -3px 0 6px $black20;
    }

    .Calendar__header,
    .Calendar__weekDays,
    .Calendar__sectionWrapper,
    .Calendar__footer
    {
        width:  330px;
        margin: auto;
    }
}
