//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

h1
{
    font-size:  30px;
    font-style: italic;
}

h2
{
    font-size: 20px;
}

h3
{
    font-size: 15px;
}

.headline
{
    position: relative;
}

.headlineBlack
{
    color: $black;
}

.headlineBlue
{
    color: $taraweraBlue;
}

.headlineWhite
{
    color: $white;
}

.headlineRed
{
    color: $red;
}

.underline
{
    position:    relative;
    font-weight: bold;

    &::after
    {
        content:       '';
        position:      absolute;
        left:          2px;
        right:         4px;
        top:           40px;
        height:        10px;
        border-radius: 10px;
    }

    &.underlineRed::after
    {
        background: $darkRed;
    }

    &.underlineBlue::after
    {
        background: $blue;
    }
}
