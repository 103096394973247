//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.simpleButton
{
    border:        none;
    border-radius: 3px;
    background:    $blue;
    color:         $white;
    text-align:    center;
    cursor:        pointer;
    display:       block;
    padding:       10px;
    margin:        10px 0 0 0;

    &[disabled]
    {
        cursor:           default;
        background-color: $gray;

        &:hover
        {
            transform: scale(1);
        }
    }
}
