//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

$inputHeight: 50px;

.textInput
{
    width:         100%;
    background:    $white;
    height:        $inputHeight;
    min-height:    $inputHeight;
    font-family:   $fontSourceGeneralBody;
    font-weight:   $fontWeightLight;
    font-size:     15px;
    color:         $black;
    outline:       none;
    padding:       11px;
    border:        1px solid $doveGray;
    border-radius: 10px;
    box-shadow:    inset 0 3px 6px -4px $black75;
    resize:        none;

    &.textInputResizable
    {
        resize: both;
    }

    &.textInputDisabled
    {
        color:  $gray;
        cursor: not-allowed;
    }

    &.textInputBold
    {
        font-weight: $fontWeightBold;
        font-size:   20px;
    }

    &.textInputMultiLine
    {
        padding:     11px;
        min-width:   150px;
        min-height:  180px;
        max-height:  500px;
        max-width:   500px;
        border:      1px solid $orientBlue;
        font-weight: $fontWeightLight;
        font-size:   15px;

        &::placeholder
        {
            font-weight: $fontWeightLight;
            font-size:   15px;
            color:       $silverChalice;
        }
    }

    &.textInputSingleLine
    {

    }

    &.textInputWithPostIcon
    {
        padding-right: $inputHeight*0.7;
    }

    &.textInputWithPreIcon
    {
        padding-left: $inputHeight*0.9;
    }

    &::placeholder
    {
        color: $silver;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button
    {
        appearance: none;
        margin:     0;
    }

    &[type="number"]
    {
        appearance: textfield;
    }

    &:focus
    {
        border-color: $gray;
    }
}
