//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.unitOverlayContent
{
    width:       100%;
    display:     flex;
    flex-flow:   column;
    align-items: center;
    padding:     0 10px;

    @media (min-width: $mobileBreakpoint)
    {
        padding: 0 60px;
    }

    button
    {
        max-width: 500px;

        @media (min-width: $mobileBreakpoint)
        {
            position: sticky;
            top:      100px;
            z-index:  100;
        }

        @include scaleTransformEffect(1.05, 1.1);
    }

    .unitOverlayContentContainer
    {
        max-width:     500px;
        width:         100%;
        display:       grid;
        gap:           27px 27px;
        align-items:   center;
        margin-top:    40px;
        margin-bottom: 50px;

        &.newLinePerElement
        {
            grid-template-columns: 1fr;
            grid-template-rows:    repeat(2, min-content);
        }

        @media (min-width: $mobileBreakpoint)
        {
            grid-template-columns: repeat(2, minmax(0, 1fr));
            grid-template-rows: min-content min-content;
        }
    }

    .hintContainer
    {
        width:           100%;
        display:         flex;
        justify-content: center;
        margin-top:      auto;
    }
}
