//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.colorButton
{
    $height:         41px;

    min-height:      $height;
    padding:         5px 9px 5px 9px;
    width:           100%;
    display:         flex;
    justify-content: center;
    align-items:     center;
    border:          0;
    cursor:          pointer;
    transition:      background-color 0.1s linear;
    border-radius:   10px;
    white-space:     pre-wrap;

    &.colorButtonColorThemeRed
    {
        background-color: $crimsonRed;
        box-shadow:       0 3px 6px $silverChalice;
        color:            $white;
    }

    &.colorButtonColorThemeGreen
    {
        background-color: $forestGreen;
        box-shadow:       0 3px 6px $silverChalice;
        color:            $white;
    }

    &.colorButtonFontThemeSmall
    {
        font-size:   12px !important;
        font-weight: $fontWeightBold;
    }

    &.colorButtonFontThemeMiddle
    {
        font-size:   15px !important;
        font-weight: $fontWeightBold;
    }

    &.colorButtonFontThemeLarge
    {
        font-size:   20px !important;
        font-weight: $fontWeightBold;
    }

    &.colorButtonFullWidth
    {
        width: 100%;
    }

    &.colorButtonColorThemeWhite
    {
        color:            $regalBlue;
        padding:          11px 7px;
        font-size:        20px;
        font-weight:      bold;
        border-radius:    10px;
        box-shadow:       0 5px 10px $black20;
        background-color: $white;

        > span
        {
            display: inline-block;
            width:   28px;
            height:  28px;
            margin:  0 5px 0 0 !important;
        }
    }

    .iconWrapper
    {
        display: inline-block;
        margin:  0 0 -1px 0;
        height:  25px;
        width:   25px;

        &.iconWrapperLeft
        {
            margin-right: 10px;
        }

        &.iconWrapperRight
        {
            margin-left: 10px;
        }
    }
}
