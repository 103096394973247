//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.overlayFooter
{
    display:        flex;
    flex-direction: column;
    position:       absolute;
    bottom:         0;
    padding:        20px 0;
    background:     $white;
    gap:            10px;

    .delayProgressBar
    {
        width:         300px;
        border-radius: 6px;
        overflow:      hidden;
        margin:        0 auto;
        border:        1px solid $alto;

        .progressBar
        {
            height:     2px;
            background: $alto;
        }
    }

    .pressArticleOverlayContentButtons
    {
        display:         flex;
        flex-flow:       row nowrap;
        gap:             20px;
        margin-top:      auto;
        width:           500px;
        justify-content: center;

        &::before
        {
            content:    '';
            position:   absolute;
            left:       0;
            top:        -15px;
            width:      100%;
            height:     15px;
            background: linear-gradient(to top, $white 0%, $transparent 100%);
        }

        button
        {
            @include scaleTransformEffect(1.05, 1.1);
        }
    }
}

.pressArticleOverlayContentContainer
{
    flex:          1;
    width:         100%;
    display:       flex;
    flex-flow:     column;
    align-items:   center;
    gap:           40px;
    margin-top:    40px;
    margin-bottom: 50px;

    h3
    {
        font-weight:   bold;
        margin-top:    25px;
        margin-bottom: 20px;
    }

    .page
    {
        display:     flex;
        flex-flow:   column;
        align-items: center;
        width:       500px;
        height:      100%;
        max-width:   100%;

        &.pageCentered
        {
            flex:            1;
            justify-content: center;
        }
    }

    .pageContent
    {
        display:     flex;
        flex-flow:   column;
        align-items: center;
        gap:         40px;
        width:       500px;
        height:      100%;
        max-width:   100%;

        > button
        {
            width:     370px;
            max-width: 100%;
        }

        h3
        {
            margin-bottom: -20px;
        }

        .pageInnerContent
        {
            width:       100%;
            display:     flex;
            flex-flow:   column;
            align-items: center;
            gap:         40px;

            > div
            {
                width: 100%;
            }
        }
    }
}

.alertBoxManagerWrapper
{
    margin-bottom: 50px;
    max-width:     400px;
}
