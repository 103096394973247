//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.profileSettingsOverlay
{
    width:       100%;
    display:     flex;
    flex-flow:   column;
    align-items: center;
    gap:         20px;

    .profileSettingsImageUpload
    {
        display: none;
    }

    > *
    {
        max-width: 500px;
    }

    img
    {
        position: relative;
        z-index:  1;
    }

    button
    {
        &:first-of-type
        {
            width:         fit-content;
            z-index:       0;
            margin-top:    -45px;
            margin-bottom: 30px;
        }

        transition: scale 0.1s linear;

        &:hover
        {
            scale: (1.05);
        }

        &:active
        {
            scale: (1.1);
        }
    }

    input
    {
        height:     37px;
        min-height: 37px;
        text-align: center;
    }

    p
    {
        margin-bottom: -20px;
    }

    h2
    {
        font-weight: bold;
        margin-top:  10px;

        &:last-of-type
        {
            margin-top: 80px;
        }
    }

    .alertBoxManagerWrapper
    {
        max-width: 400px;
    }
}
