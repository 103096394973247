//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.vehicleListItem
{
    width:            100%;
    height:           100%;
    display:          flex;
    flex-flow:        row nowrap;
    align-items:      center;
    justify-content:  space-between;
    color:            $taraweraBlue;
    padding:          12px 14px;
    box-shadow:       0 5px 10px $black20;
    border-radius:    10px;
    background-color: $white;

    .vehicleListItemText
    {
        display:       flex;
        flex-flow:     column;
        word-break:    break-word;
        overflow-wrap: break-word;
        hyphens:       auto;

        h3
        {
            font-size:   20px;
            font-weight: bold;
        }

        p
        {
            font-size:   15px;
            font-weight: 300;
        }
    }

    .vehicleListItemButtons
    {
        display:      flex;
        flex-flow:    row nowrap;
        gap:          20px;
        padding-left: 10px;
        margin-left:  auto;

        span
        {
            @include scaleTransformEffect(1.15, 1.2);
        }
    }
}
