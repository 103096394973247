//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

$height:  10px;
$dotSize: $height * 1.6;

.stepIndicator
{
    position:      relative;
    width:         100%;
    height:        $height;
    margin-bottom: 50px;

    .stepIndicatorFullBar
    {
        position:         absolute;
        top:              0;
        left:             0;
        width:            100%;
        height:           100%;
        background-color: $galleryGray;

        .stepIndicatorFullBarActive
        {
            position:         absolute;
            top:              0;
            left:             0;
            width:            0;
            height:           100%;
            background-color: $screaminGreen;

            .tooltipWrapper
            {
                position:  absolute;
                top:       150%;
                right:     0;
                transform: translateX(50%);
            }
        }
    }

    .stepIndicatorDotContainer
    {
        display:         flex;
        flex-flow:       row nowrap;
        justify-content: space-between;
        margin:          0 $dotSize * -.5;

        .stepIndicatorDot
        {
            position:         relative;
            width:            $dotSize;
            height:           $dotSize;
            border-radius:    $dotSize * .5;
            background-color: $white;
            border:           1px solid $galleryGray;
            margin-top:       $dotSize * -.2;
            cursor:           default;

            &.stepIndicatorDotActive
            {
                border-color:     $screaminGreen;
                background-color: $screaminGreen;
            }

            &.stepIndicatorDotVisited
            {
                cursor: pointer;
            }
        }
    }
}
