//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.stepIndicatorToolTip
{
    margin-top:       20px;
    position:         relative;
    width:            120px;
    height:           24px;
    background-color: $mineShaftGrey;
    border-radius:    4px;
    display:          flex;
    justify-content:  center;

    .stepIndicatorToolTipText
    {
        position:    relative;
        z-index:     1;
        font-size:   12px;
        color:       $white;
        display:     flex;
        align-items: center;
    }

    .triangle
    {
        position: absolute;
        z-index:  0;
        color:    $mineShaftGrey;
        top:      -15px;
        width:    25px;
        height:   20px;

        @include svgColor($mineShaftGrey);
    }
}
