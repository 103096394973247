//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.dashboardButton
{
    display:         flex;
    flex-flow:       column;
    justify-content: center;
    padding:         15px 8px 8px;
    box-shadow:      0 5px 15px 0 $black30;
    cursor:          pointer;
    gap:             9px;
    border:          0;
    white-space:     pre-wrap;
    border-radius:   10px;

    @include scaleTransformEffect(1.05, 1.1);

    &.dashboardButtonRed
    {
        color:            $white;
        background-color: $monzaRed;
    }

    &.dashboardButtonBlueBorder
    {
        color:            $orientBlue;
        border:           2px solid $orientBlue;
        box-shadow:       none;
        background-color: transparent;
    }

    &.dashboardButtonGreen
    {
        color:            $white;
        background-color: $brightGreen;
    }

    &.dashboardButtonDisabled
    {
        color:            $white;
        background-color: $black30;
        cursor:           auto;
        pointer-events:   none;
    }

    &.dashboardButtonRow
    {
        flex-flow: row;
        gap:       30px;
        padding:   16px 41px;
    }

    .dashboardButtonIcons
    {
        display:         flex;
        gap:             8px;
        justify-content: center;
        align-self:      center;

        .dashboardButtonIcon
        {
            $size:  52px;

            width:  $size;
            height: $size;

            &.multipleIcons
            {
                $size:  42px;

                width:  $size;
                height: $size;
            }
        }
    }

    .dashboardButtonTextWrapper
    {
        display:    flex;
        flex-flow:  column;
        align-self: center;

        .dashboardButtonTitle
        {
            font-size:   20px;
            text-align:  left;
            font-weight: bold;
        }

        .dashboardButtonSubText
        {
            text-align:  left;
            font-weight: lighter;
        }
    }
}
