//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.logoHeader
{
    position:                   sticky;
    top:                        0;
    z-index:                    $zIndexOverlayAbove;
    width:                      100%;
    display:                    flex;
    flex-flow:                  row nowrap;
    padding:                    20px 0;
    gap:                        10%;
    justify-content:            center;
    align-items:                center;
    border-bottom-left-radius:  30px;
    border-bottom-right-radius: 30px;
    box-shadow:                 0 3px 20px $silverChalice;
    background-color:           $white;

    @media (min-width: $mobileBreakpoint)
    {
        display: none;
    }

    .logo
    {
        width:      200px;
        max-height: 50px;
        margin:     0;
        padding:    0;
    }
}
