//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.confirmOverlayContent
{
    display:         flex;
    flex-direction:  column;
    justify-content: center;
    align-items:     center;
    text-align:      center;
    gap:             50px;
    width:           80%;
    height:          100%;

    .confirmOverlayButtonWrapper
    {
        display:         flex;
        flex-direction:  row;
        gap:             20px;
        align-items:     center;
        justify-content: center;

        button
        {
            min-width:   80px;
            font-weight: bold;
            font-size:   18px;

            @include scaleTransformEffect(1.1, 1.2);
        }

        button:is(:first-child)
        {
            background-color: $red;
        }

        button:is(:last-child)
        {
            background-color: $forestGreen;
        }
    }
}
