//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import 'styles/all';

*
{
    margin:     0;
    padding:    0;
    box-sizing: border-box;
}

// Use these 2 styles to debug tab index and focus issues
//*[tabindex]
//{
//    outline: solid yellow 3px;
//}
//
//*:focus
//{
//    outline: solid magenta 3px !important;
//}

h1,
h2,
h3,
h4,
h5,
h6
{
    font-weight: unset;
}

:global
{
    body
    {
        margin:                  0;
        padding:                 0;
        font-size:               20px;
        font-family:             $fontSourceGeneralBody;
        -webkit-font-smoothing:  antialiased;
        -moz-osx-font-smoothing: grayscale;
        width:                   100%;
        overflow-x:              hidden;

        @include headlineColor($black);
    }
}

a,
button
{
    cursor:       pointer;
    text-align:   center;
    touch-action: manipulation;
}

a
{
    color:           $funBlue;
    text-decoration: none;
}

code
{
    font-family: $fontSourceCourierNew;
}

:global(.no-scroll)
{
    position:   fixed;
    top:        var(--st, 0);
    overflow-y: scroll;
}
