//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.assistantSelectionButton
{
    $blackTransparent: transparentize($black, 0.84);

    display:           flex;
    flex-direction:    column;
    justify-content:   center;
    align-items:       center;
    border-radius:     10pt;
    box-shadow:        $blackTransparent 0 1pt 10pt 7pt;
    cursor:            pointer;
    background-color:  unset;

    &:hover
    {
        box-shadow: $blackTransparent 1pt 10pt 7pt, $blackTransparent 0 0 0 2pt;
        opacity:    1 !important;
    }

    &.active
    {
        border: 2pt solid $taraweraBlue;
    }

    &.inactive
    {
        opacity: 0.5;
    }

    .assistantSelectionButtonImage
    {
        padding-top:   8px;
        padding-left:  45px;
        padding-right: 45px;

        > svg
        {
            width:      100%;
            max-height: 135px;
            min-height: 20px;
        }
    }

    .assistantSelectionButtonText
    {
        display:         flex;
        justify-content: center;
        margin-top:      16px;
        margin-bottom:   13px;

        h2
        {
            @media (max-width: 500px)
            {
                font-size: 12px;
            }
        }
    }
}
