//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.planSwiper
{
    overflow: visible !important;

    :global(.swiper-wrapper)
    {
        max-height: 100%;
        height:     100%;
        display:    flex;

        :global(.swiper-slide)
        {
            width:       auto;
            flex-shrink: 0;
            display:     block;
            height:      100%;
            max-height:  100%;
        }
    }

    :global(.swiper-pagination)
    {
        position:      relative;
        border:        1px solid $black10;
        width:         fit-content;
        margin:        20px auto 0 auto;
        border-radius: 10px;
        padding:       4px 1px;
        display:       flex;
        box-shadow:    0 0 10px 0 $black10;

        &:has(:global(.swiper-pagination-bullet:only-child))
        {
            display: none;
        }

        :global(.swiper-pagination-bullet)
        {
            background:    $regalBlue;
            width:         20px;
            border-radius: 10px;
            display:       block;
        }
    }
}
