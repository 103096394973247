//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.avatar
{
    $size:           95px;

    display:         flex;
    border:          4pt solid $white;
    border-radius:   50%;
    justify-content: center;
    align-items:     center;
    width:           $size;
    height:          $size;
    overflow:        hidden;
    margin-bottom:   10px;

    .avatarImage
    {
        background: $white;

        > img
        {
            object-fit:    cover;
            width:         $size;
            height:        $size;
            border-radius: 50%;
        }

        &.fallbackImage
        {
            > img
            {
                object-fit: contain;
                margin-top: 20pt;
            }
        }
    }
}
