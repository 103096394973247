//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.overlay
{
    display:        flex;
    align-items:    center;
    top:            0;
    left:           0;
    opacity:        1;
    background:     $black50;
    z-index:        $zIndexOverlay;
    position:       fixed;
    width:          100%;
    height:         100%;
    overflow:       hidden;
    color:          $black;
    pointer-events: all;
    transition:     opacity .2s linear;

    .overlayContent
    {
        $size:            875px;
        $maxContentWith:  350px;

        width:            100%;
        height:           100%;
        max-height:       100vh;
        margin:           0 auto 0 auto;
        background-color: $white;
        border-radius:    10px;
        display:          flex;
        flex-direction:   column;
        align-items:      center;
        overflow:         hidden;
        position:         relative;
        transition:       margin-top .2s ease-out;

        @media (min-width: $mobileBreakpoint)
        {
            width:      $size;
            height:     fit-content;
            min-height: 875px;
            max-height: 90vh;
            margin-top: 60px;
        }

        .overlayContentInner
        {
            flex:           1;
            display:        flex;
            flex-direction: column;
            align-items:    center;
            width:          100%;
            padding:        100px 20px 20px 20px;
            overflow-y:     auto;
            overflow-x:     hidden;

            @media (min-width: $mobileBreakpoint)
            {
                padding-top: 0;
            }

            .overlayContentTitle
            {
                position:         relative;
                z-index:          1;
                top:              0;
                width:            100%;
                min-height:       100px;
                font-size:        40px;
                font-weight:      $fontWeightBold;
                text-align:       center;
                font-style:       italic;
                padding-top:      40px;
                padding-bottom:   20px;
                margin-bottom:    15px;
                background-color: $white;

                @media (min-width: $mobileBreakpoint)
                {
                    position: sticky;
                }

                &::after
                {
                    content:    '';
                    position:   absolute;
                    top:        100%;
                    left:       0;
                    width:      100%;
                    height:     15px;
                    background: linear-gradient(to bottom, $white 0%, $transparent 100%);
                }

                .overlayContentCloseButton
                {
                    $size:    54px;
                    $padding: 24px;

                    color:    $white;
                    position: absolute;
                    height:   $size;
                    width:    $size;
                    top:      $padding;
                    right:    $padding;
                    display:  none;

                    @include svgColorFill($black);

                    @media (min-width: $mobileBreakpoint)
                    {
                        display: block;
                    }
                }
            }

            &.overlayContentBlue
            {
                backdrop-filter:  blur(10px);
                background-color: $orientBlue83;

                .overlayContentCloseButton
                {
                    @include svgColorFill($white);
                }
            }
        }
    }

    &.overlayHidden
    {
        opacity:        0;
        pointer-events: none;

        .overlayContent
        {
            margin-top: 300px;
        }
    }

    &.overlayContentSmall
    {
        .overlayContent
        {
            min-height: 0;

            .overlayContentTitle
            {
                font-size: 20px;
            }
        }
    }

    &.overlayMobileTransparent
    {
        .overlayContent
        {
            @media (max-width: $mobileBreakpointMax)
            {
                backdrop-filter:  blur(5px);
                background-color: $white60;
            }

            .overlayContentInner
            {
                .overlayContentTitle
                {
                    background-color: $transparent;
                    @media (min-width: $mobileBreakpoint)
                    {
                        background-color: $white;
                    }

                    &::after
                    {
                        display: none;
                        @media (min-width: $mobileBreakpoint)
                        {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}
