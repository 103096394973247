//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.planDisplayItem
{
    position:        relative;
    background:      $lightSilver;
    display:         flex;
    flex-direction:  column;
    justify-content: space-between;
    max-width:       360px;
    user-select:     none;
    transition:      box-shadow 0.2s ease-in-out,
                     border 0.2s ease-in-out;
    border-radius:   14px;
    margin:          0 auto;

    &:hover
    {
        box-shadow: 0 0 10px 0 $black10;
    }

    .planItemLabel
    {
        position:        absolute;
        width:           100%;
        transform:       translateY(-100%);
        display:         flex;
        justify-content: center;

        .planItemLabelContent
        {
            border-radius: 14px 14px 0 0;
            background:    $regalBlue;
            color:         $white;
            padding:       4px 20px 0 20px;
            font-weight:   $fontWeightBold;
            font-size:     16px;
        }
    }

    .planItemContent
    {
        display:        flex;
        flex-direction: column;
        padding:        20px;
        border:         3px solid $lightSilver;
        border-bottom:  none;
        border-radius:  14px;

        .planName
        {
            text-align:    center;
            font-size:     26px;
            margin-bottom: 30px;
        }

        .price
        {
            font-weight:   $fontWeightBold;
            font-size:     26px;
            margin-bottom: 20px;
            text-align:    center;

            span
            {
                font-size:   16px;
                font-weight: $fontWeightRegular;
            }
        }

        .planDescription
        {
            font-size:  16px;
            text-align: center;
            height:     60px;
        }

        .separator
        {
            height:     2px;
            width:      100%;
            background: $doveGray;
            margin:     20px 0;
        }

        .numberOfWords
        {
            font-size:   15px;
            color:       $regalBlue;
            font-weight: $fontWeightBold;
            text-align:  center;
        }

        .planFeatures
        {
            .planFeature
            {
                font-size: 15px;
            }
        }
    }

    .planItemButton
    {
        display:         flex;
        justify-content: center;
        background:      $regalBlue;
        border:          none;
        width:           100%;
        padding:         16px 8px;
        font-size:       24px;
        color:           $white;
        font-weight:     $fontWeightBold;
        border-radius:   0 0 14px 14px;

        &:hover
        {
            text-decoration: underline;
        }
    }

    &.highlight
    {
        .planItemContent
        {
            border-radius: 14px 14px 0 0;
            border:        4px solid $regalBlue;
            border-bottom: none;
        }
    }
}
