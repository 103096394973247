//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.pagingButton
{
    padding:         13px 34px;
    border-radius:   10px;
    color:           $white;
    cursor:          pointer;
    justify-content: center;
    display:         flex;
    align-items:     center;
    gap:             15px;
    width:           82px;
    border:          none;
    background:      none;

    .pagingButtonIcon
    {
        $size:  19px;

        width:  $size;
        height: $size;

        @include svgColorFill($white);
    }

    .pagingButtonTitle
    {
        font-size:   12px;
        font-weight: $fontWeightBold;
        line-height: 15px;
        text-align:  left;
    }

    &.pagingButtonWithText
    {
        width:   165px;
        padding: 7.5px 21px;
    }

    &.backgroundForward
    {
        background-color: $taraweraBlue;
    }

    &.backgroundBackward
    {
        background-color: $monzaRed;

        > div > span
        {
            transform: rotate(180deg);
        }
    }

    &.backgroundQuicksave
    {
        background-color: $brightGreen;
    }

    &.pagingButtonDisabled
    {
        background-color: $alto;
    }
}
