//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.mobileMenuOverlay
{
    max-width:      100%;
    display:        flex;
    flex-direction: column;
    align-items:    center;

    h3
    {
        font-size:   20px;
        font-weight: bold;
        color:       $black;

        + span
        {
            margin-bottom: 40px;
        }
    }

    button
    {
        width:           350px;
        max-width:       100%;
        height:          70px;
        padding-top:     20px !important;
        padding-bottom:  20px !important;
        margin-top:      20px;
        text-align:      left;
        justify-content: normal;

        @include scaleTransformEffect(1.1, 1.15);

        > span
        {
            min-width: 30% !important;
        }

        &:nth-child(5)
        {
            svg
            {
                width:  40px;
                height: auto;
            }
        }
    }
}
