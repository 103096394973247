//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.alertBox
{
    display:       flex;
    padding:       12px 40px 12px 24px;
    border-radius: 10px;
    font-size:     15px;
    border-width:  1px;
    border-style:  solid;
    margin:        0 0 20px 0;
    position:      relative;
}

.alertBoxMessage
{
    display:     flex;
    align-items: center;
}

.alertBoxCloseButtonWrapper
{
    position: absolute;
    top:      10px;
    right:    10px;

    > button
    {
        padding:          5px;
        margin-top:       0 !important;
        border:           none;
        background-color: transparent;

        > div
        {
            width: 18px !important;
        }
    }
}

/* stylelint-disable no-descending-specificity */
// The rule is disabled because its not solvable no matter which order they are in.
.alertBoxSuccess
{
    border-color: $forestGreen;
    background:   $lightGreen;
    color:        $forestGreen;

    svg
    {
        width: 80px;

        @include svgColor($forestGreen, true);
    }

    .alertBoxIconLeft
    {
        background:   $forestGreen;
        margin-right: 20px;
    }

    .alertBoxIconRight
    {
        background:  $forestGreen;
        margin-left: 20px;
    }
}

.alertBoxError
{
    border-color: $red;
    background:   $red;
    color:        $white;

    svg
    {
        width: 80px;

        @include svgColor($white);
    }

    .alertBoxIconLeft
    {
        background:   $red;
        margin-right: 20px;
    }

    .alertBoxIconRight
    {
        background:  $red;
        margin-left: 20px;
    }
}

/* stylelint-enable no-descending-specificity */
