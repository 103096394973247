//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

/* stylelint-disable selector-pseudo-class-no-unknown */

@mixin svgColor($color, $important: false)
{
    @include svgColorFill($color, $important);
    @include svgColorStroke($color, $important);
}

@mixin svgColorFill($color, $important: false)
{
    :global
    {
        .fill
        {
            @if $important
            {
                fill: $color !important;
            }
            @else
            {
                fill: $color;
            }
        }
    }
}

@mixin svgColorStroke($color, $important: false)
{
    :global
    {
        .stroke
        {
            @if $important
            {
                stroke: $color !important;
            }
            @else
            {
                stroke: $color;
            }
        }
    }
}
