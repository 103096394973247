//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.adBanner
{
    margin-top: 40px;
    width:      100%;

    &.small
    {
        height: 120px;
    }

    &.medium
    {
        height: 360px;
    }

    &.big
    {
        height: 400px;
    }
}
