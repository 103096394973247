.landingpageFooter
{
    display:        flex;
    flex-direction: column;
    align-items:    flex-start;
    margin-top:     auto;
    text-align:     left;
    list-style:     none;

    a
    {
        font-size: 15px;
    }
}
