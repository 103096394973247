//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

$smallAngle: calc(100% / ($goldenRatio + 1));
$halfAngle:  50% - $smallAngle;

.registerTestAccountScreen
{
    display:   flex;
    flex-flow: column;

    @media (min-width: $mobileBreakpoint)
    {
        flex-flow: row nowrap;
    }

    .registerTestAccountScreenContent
    {
        flex:            1;
        display:         flex;
        align-items:     center;
        justify-content: center;

        @media (min-width: $mobileBreakpoint)
        {
            flex: $halfAngle;
        }

        .registerTestAccountScreenForm
        {
            width:     564px;
            max-width: 100%;
            padding:   30px 20px;
            display:   flex;
            flex-flow: column;
            gap:       30px;

            @media (min-height: 650px) and (min-width: $mobileBreakpoint)
            {
                gap: 30px;
            }

            label
            {
                display:     flex;
                flex-flow:   column;
                font-weight: 300;

                span
                {
                    display:         inline-flex;
                    flex-flow:       row wrap;
                    justify-content: space-between;
                    margin-bottom:   12px;

                    a
                    {
                        margin-left: auto;
                        white-space: nowrap;
                    }
                }

                input
                {
                    padding:       11px 18px;
                    border:        1px solid $doveGray;
                    border-radius: 10px;
                    font-size:     20px;
                    font-weight:   bold;

                    @include placeholderColor($silver);
                }
            }

            form
            {
                display:   flex;
                flex-flow: column;

                > label
                {
                    margin-bottom: 20px;
                }

                > div
                {
                    display:       flex;
                    gap:           20px;
                    margin-bottom: 20px;

                    > label
                    {
                        width: calc(50% - 10px);
                    }
                }
            }

            > div:is(:first-child)
            {
                width:  364px;
                margin: 0 auto;
            }

            .registerTestAccountScreenFormText
            {
                font-size:   15px;
                white-space: pre-line;

                p:not(:last-child)
                {
                    margin-bottom: 20px;
                }
            }

            .registerTextAccountCheckboxWrapper
            {
                display: flex;
                width:   100%;

                div:first-child
                {
                    div:first-child
                    {
                        div:first-child
                        {
                            border: 1px solid $black !important;

                            svg
                            {
                                @include svgColor($black);
                            }
                        }
                    }
                }
            }

            img
            {
                width:     200px;
                max-width: 100%;
            }

            button
            {
                width:         100%;
                border-radius: 10px;
                padding:       16px 0;
                font-weight:   bold;
                font-size:     16px;

                @include scaleTransformEffect(1.05, 1.1);
            }

            .alertBoxWrapper
            {
                margin: 20px 0;
            }
        }

        .honeypot
        {
            display: none;
        }
    }

    .registerTestAccountScreenStyle
    {
        flex:                $halfAngle;
        height:              100vh;
        background-size:     cover;
        background-image:    url('../../assets/images/login.jpg');
        background-position: center;
    }
}
