//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.textSwitch
{
    display:       flex;
    padding:       2px;
    border:        1px solid $regalBlue;
    border-radius: 26px;
    gap:           5px;

    .switchItem
    {
        padding:       6px 20px;
        color:         $regalBlue;
        font-weight:   $fontWeightBold;
        font-size:     14px;
        cursor:        pointer;
        user-select:   none;
        border-radius: 26px;
        position:      relative;
        transition:    background .1s ease-in-out,
                       color .1s ease-in-out;

        &.active
        {
            background: $regalBlue;
            color:      $white;
        }

        .itemLabel
        {
            font-size:       10px;
            display:         flex;
            justify-content: center;
            position:        absolute;
            top:             85%;
            left:            0;
            right:           0;

            span
            {
                background:    $brightRed;
                color:         $white;
                border-radius: 14px;
                padding:       3px 6px;
            }
        }
    }
}
