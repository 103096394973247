//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.sideMenu
{
    position:                   sticky;
    top:                        0;
    background-color:           $regalBlue;
    width:                      262px;
    min-width:                  262px;
    height:                     100vh;
    z-index:                    $zIndexDefault;
    flex-direction:             column;
    display:                    none;
    gap:                        40px;
    box-shadow:                 20px 0 30px 0 $black30;
    border-top-right-radius:    20px;
    border-bottom-right-radius: 20px;
    overflow-y:                 auto;

    @include hideScrollbar;

    @media (min-width: $mobileBreakpoint)
    {
        display: flex;
    }

    .sideMenuButtons
    {
        flex-direction:  column;
        display:         flex;
        gap:             25px;
        justify-content: center;
        align-items:     flex-start;
        padding-left:    51px;
    }

    .sideMenuLogo
    {
        padding-top:   34px;
        padding-left:  95px;
        padding-right: 95px;

        > div > svg
        {
            height: 63px;
            width:  100%;
        }
    }

    ul
    {
        padding-left:   51px;
        padding-bottom: 39px;

        a
        {
            color: $white;
        }
    }
}
