//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.slider
{
    width: 90%;

    :global(.rc-slider)
    {
        cursor: pointer;
    }

    .sliderTextWrapper
    {
        display:         flex;
        align-items:     center;
        justify-content: center;
        text-align:      center;

        .sliderValue
        {
            font-weight:  bold;
            margin-right: 3px;

            input
            {
                height:       30px;
                padding:      0;
                max-width:    50px;
                min-height:   30px;
                margin-right: 10px;
                text-align:   center;
                font-weight:  bold;
            }
        }
    }
}
