//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.editPressArticleOverlay
{
    display:         flex;
    flex-flow:       column;
    align-items:     center;
    justify-content: space-between;
    width:           90%;

    .buttonRow
    {
        gap:             11px;
        display:         flex;
        justify-content: space-between;
        width:           85%;
        flex-wrap:       wrap;

        @media (min-width: $mobileBreakpoint)
        {
            flex-wrap: nowrap;
            gap:       33px;
        }

        button
        {
            @include scaleTransformEffect(1.1, 1.2);
        }
    }

    .editPressArticleOverlayContentContainer
    {
        width:          100%;
        height:         100%;
        margin-top:     45px;
        flex-direction: column;
        align-items:    center;
        display:        flex;
        gap:            26px;

        .buttonRow
        {
            button
            {
                @include scaleTransformEffect(1.05, 1.1);
            }
        }

        .alertBoxManagerWrapper
        {
            max-width: 400px;
        }
    }
}
