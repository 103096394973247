//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.header
{
    background-color: $doveGray;
    border-radius:    0.5rem 0.5rem 0 0;
}

.content
{
    height:        100%;
    border:        2px solid $doveGray;
    border-radius: 0 0 0.5rem 0.5rem;
}

.title
{
    color: $white;
}

.buttonGroup
{
    @include scaleTransformEffect(1.15, 1.2);

    * > svg
    {
        color: $white;
    }
}

.subtitle
{
    margin-bottom: 1em;
}
