//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.iconTipWrapper
{
    position:        absolute;
    display:         flex;
    min-width:       300px;
    z-index:         1;
    top:             100%;
    right:           0;
    justify-content: center;
    animation:       showTip 500ms ease;

    @media (min-width: $mobileBreakpoint)
    {
        left:      0;
        min-width: 400px;
    }

    .iconTipTriangle
    {
        display:  flex;
        position: absolute;
        top:      -15px;
        color:    $taraweraBlue;
        width:    25px;
        height:   20px;

        @include svgColor($taraweraBlue);
    }
}

.iconTip
{
    $size:            84px;

    border-radius:    10pt;
    display:          flex;
    flex-direction:   column;
    background-color: $taraweraBlue;
    min-height:       $size;
    justify-content:  space-between;
    max-width:        352pt;
    position:         relative;

    @media (min-width: $mobileBreakpoint)
    {
        flex-direction: row;
        gap:            10pt;

        .iconTipIcon
        {
            margin: auto 0 auto 30px;
        }

        .textWrapper
        {
            margin-right: 26pt;
        }
    }

    .textWrapper
    {
        display:     flex;
        align-items: center;
        padding:     12px;
        font-weight: $fontWeightLight;
    }

    .iconTipIcon
    {
        display: flex;
        height:  127px;
        width:   100%;

        @include svgColor($white);
    }
}

@keyframes showTip
{
    0%
    {
        opacity:   0;
        transform: translateY(10px);
    }

    100%
    {
        opacity:   1;
        transform: translateY(0);
    }
}
