//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@mixin scaleTransformEffect($hoverScale, $activeScale)
{
    transition: transform 0.1s linear;

    &:hover
    {
        transform: scale($hoverScale);
    }

    &:active
    {
        transform: scale($activeScale);
    }
}
