//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.userSettingsOverlay
{
    display:     flex;
    flex-flow:   column;
    align-items: center;
    gap:         35px;
    width:       100%;

    @media (min-width: $mobileBreakpoint)
    {
        padding-top: 50px;
    }

    .changePasswordContent
    {
        display:     flex;
        flex-flow:   column;
        align-items: center;
        gap:         20px;

        p
        {
            font-size:     16px;
            margin-bottom: -20px;
        }

        input
        {
            height:     37px;
            min-height: 37px;
            text-align: center;
        }

        button
        {
            width:      fit-content;
            margin-top: 10px;

            @include scaleTransformEffect(1.05, 1.1);
        }

        .passwordContainer
        {
            width: 100%;

            &.passwordMismatch
            {
                :global
                {
                    input
                    {
                        border: 1px solid red;
                    }
                }
            }
        }

        .alertBoxManagerWrapper
        {
            max-width: 400px;
        }
    }

    .updateNotificationSettingsWrapper
    {
        display:     flex;
        flex-flow:   column;
        gap:         20px;
        align-items: center;

        > div:first-child
        {
            *
            {
                color:        $black;
                border-color: $black;

                @include svgColor($black);
            }
        }

        button
        {
            width:  fit-content;
            margin: 10px auto 0 auto;

            @include scaleTransformEffect(1.05, 1.1);
        }
    }
}
