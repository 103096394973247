//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.userProfilePreviewWrapper
{
    justify-content: center;
    align-items:     center;
    flex-direction:  column;
    display:         flex;
    font-weight:     $fontWeightBold;

    .userProfileSubtitle
    {
        font-size:   10px;
        color:       $white;
        font-weight: $fontWeightLight;
    }

    h3
    {
        font-size:   15px;
        font-weight: bold;
        text-align:  center;
        padding:     0 5px;
    }
}
