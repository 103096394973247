//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.multipleChoiceSelector
{
    display:         flex;
    gap:             10px;
    flex-wrap:       wrap;
    align-items:     center;
    justify-content: center;

    .value
    {
        // TODO: https://lulububu.atlassian.net/browse/FIREWRITER-132
        font-family:     $fontSourceGeneralBody;
        cursor:          pointer;
        font-size:       15px;
        border:          2px solid $black;
        padding:         8px 15px;
        border-radius:   15px;
        display:         flex;
        flex-direction:  row;
        align-items:     center;
        justify-content: center;
        gap:             4px;
        margin:          0 14px;

        @include scaleTransformEffect(1.05, 1.1);

        &.isSelected
        {
            border-color:     $planGreen;
            background-color: $lightGreen;
            font-weight:      bold;
            margin:           0;
        }

        .wrappedIcon
        {
            width:  20px;
            height: 20px;

            svg
            {
                color:  $planGreen;
                width:  unset;
                height: unset;
            }
        }
    }
}
