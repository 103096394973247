//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.planStatus
{
    width:            100%;
    background-color: $blue;
    color:            $white;
    display:          flex;
    flex-direction:   column;
    align-items:      center;
    padding:          30px;
    border-radius:    10px;
    box-shadow:       0 0 30px 0 $black30;

    .header
    {
        font-size:   30px;
        font-style:  italic;
        // TODO: https://lulububu.atlassian.net/browse/FIREWRITER-132
        font-family: $fontSourceGeneralBody;

        strong
        {
            position:    relative;
            font-weight: bold;

            &::after
            {
                content:          '';
                position:         absolute;
                bottom:           -2px;
                left:             0;
                width:            100%;
                height:           7px;
                border-radius:    4px;
                background-color: $white;
            }
        }
    }

    .wordsLeftContainer
    {
        width:           100%;
        position:        relative;
        display:         flex;
        justify-content: center;
        align-items:     center;

        .wordsLeftInner
        {
            width:           100%;
            height:          100%;
            font-size:       20px;
            line-height:     1;
            display:         flex;
            justify-content: center;
            flex-direction:  column;
            align-items:     center;
            position:        absolute;
            text-align:      center;

            .percentage
            {
                font-size:   20px;
                font-weight: bold;
            }
        }
    }

    .currentPlan
    {
        line-height: 1;
        font-size:   20px;
        text-align:  center;
    }
}
