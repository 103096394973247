//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.stagingLoginOverlayContainer
{
    position:         absolute;
    z-index:          $zIndexStagingAuthentication;
    top:              0;
    left:             0;
    width:            100vw;
    height:           100vh;
    display:          flex;
    flex-flow:        row nowrap;
    align-items:      center;
    justify-content:  center;
    background-color: $white;

    .stagingLoginOverlay
    {
        display:     flex;
        flex-flow:   row nowrap;
        align-items: stretch;
        width:       400px;

        input
        {
            width: 300px;
        }

        > button
        {
            margin-left: 10px;
        }
    }
}
