//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.planCircleWrapper
{
    overflow: visible;

    .planCircle
    {
        $size:            6.25rem;

        transition:       0.35s stroke-dashoffset;
        transform:        rotate(-90deg);
        transform-origin: $size $size;
    }
}
