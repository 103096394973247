//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.notFoundScreen
{
    display:     flex;
    flex-flow:   row nowrap;
    align-items: flex-start;

    .contentWrapper
    {
        width:      100%;
        margin-top: 200px;
        z-index:    $zIndexDefault;

        .content
        {
            display:        flex;
            flex-direction: column;
            align-items:    center;
            text-align:     center;

            h1
            {
                font-size:   80px;
                font-weight: $fontWeightBold;
            }

            h2
            {
                font-size:   40px;
                font-weight: $fontWeightLight;
            }

            @media screen and (max-width: $screen-md)
            {
                h1
                {
                    font-size: 60px;
                }

                h2
                {
                    font-size: 40px;
                }
            }

            .buttonContainer
            {
                margin-top: 40px;
                width:      250px;
            }
        }
    }

    .firefighterBackground
    {
        > img
        {
            height:   calc(100% - 40px);
            position: absolute;
            right:    0;
            bottom:   -20%;
            opacity:  0.2;
            z-index:  0;
        }

        @media screen and (max-width: $screen-lg)
        {
            > img
            {
                height: 60%;
                bottom: 0;
            }
        }

        @media screen and (max-width: $screen-md)
        {
            > img
            {
                right: -5%;
            }
        }

        @media screen and (max-width: $screen-sm)
        {
            > img
            {
                height: 50%;
                right:  -15%;
            }
        }
    }
}
