//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.editor
{
    font-family: $fontSourceGeneralBody;
    font-size:   12pt;
    height:      420px;
    overflow-y:  scroll;

    h1
    {
        font-family: $fontSourceGeneralBody;
        font-size:   16pt;
        font-style:  normal;
    }
}

.toolbar
{
    border:      none;
    font-family: $fontSourceGeneralBody;
    font-size:   16pt;
    padding:     0;
}

.editorButton
{
    $size:         36pt;

    width:         $size;
    height:        $size;
    border-radius: 10pt;
    margin:        0 0 0 4px;
    font-size:     $size;
    padding:       0;

    &:first-child
    {
        margin-left: 0;
    }

    &:hover
    {
        background-color: $white80;
        box-shadow:       1px 1px 0 transparentize($doveGray, 0.8);
    }

    &[aria-selected="true"]
    {
        background-color: transparentize($doveGray, 0.8);
        box-shadow:       none;
    }
}
