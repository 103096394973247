//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.planFeature
{
    display:     flex;
    height:      80px;
    gap:         10px;
    align-items: center;

    .featureStatus
    {
        width:         30px;
        height:        30px;
        border-radius: 100%;
        flex-shrink:   0;

        svg
        {
            $size:  16px;

            width:  $size;
            height: $size;
        }

        &.active
        {
            background: $calmGreen;
            color:      $white;
        }

        &:not(.active)
        {
            background: $labelRed;
            color:      $white;
        }
    }

    .featureText
    {
        display:         flex;
        flex-direction:  column;
        justify-content: center;
        gap:             5px;
        font-size:       14px;

        div:last-child
        {
            font-weight: $fontWeightBold;
        }
    }
}
