//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.listEntryCounter
{
    margin-top:     40px;
    width:          100%;
    display:        flex;
    flex-direction: column;
    align-items:    center;

    .progressBarWrapper
    {
        border-radius: 5px;
        overflow:      hidden;
        max-width:     300px;
        background:    $brightGreen;
        width:         100%;
        height:        20px;

        .progressBar
        {
            background-color: $red;
            height:           100%;
            transition:       width .2s ease-out .2s;
        }
    }

    p
    {
        font-size:  16px;
        max-width:  500px;
        text-align: center;
    }
}
