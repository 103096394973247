//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.buttonGroup
{
    display:        flex;
    flex-direction: row;
    gap:            10px;
    position:       absolute;
    right:          20px;
    width:          30px;

    > *
    {
        width:  64px;
        height: 64px;
        cursor: pointer;

        svg
        {
            color: $white;
        }
    }
}

.contentButtons
{
    display:         flex;
    flex-direction:  row;
    justify-content: end;
    width:           100%;

    > *
    {
        width:  20px;
        height: 20px;

        svg
        {
            color:  $black;
            cursor: pointer;
            @include scaleTransformEffect(1.2, 1.2);
        }
    }
}

.errorIcon
{
    color:  $red;
    width:  70px;
    height: 70px;
}
