//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

$smallAngle: calc(100% / ($goldenRatio + 1));
$largeAngle: 100% - $smallAngle;

.loginScreen
{
    height:    100vh;
    display:   flex;
    flex-flow: column;

    @media (min-width: $mobileBreakpoint)
    {
        flex-flow: row nowrap;
    }

    .loginScreenContent
    {
        flex:            1;
        display:         flex;
        align-items:     center;
        justify-content: center;

        @media (min-width: $mobileBreakpoint)
        {
            flex: $smallAngle;
        }

        .loginScreenForm
        {
            width:     364px;
            max-width: 100%;
            padding:   30px 20px;
            display:   flex;
            flex-flow: column;
            gap:       30px;

            @media (min-height: 650px) and (min-width: $mobileBreakpoint)
            {
                gap: 60px;
            }

            img
            {
                width:     200px;
                max-width: 100%;
            }

            p
            {
                font-size:   20px;
                font-weight: bold;
            }

            form
            {
                margin-top: 80px;

                @media (min-width: $mobileBreakpoint)
                {
                    margin-top: 0;
                }

                div:first-child
                {
                    div:first-child
                    {
                        border: 1px solid $black !important;

                        svg
                        {
                            @include svgColor($black);
                        }
                    }

                    div:nth-child(2)
                    {
                        color: $black;
                    }
                }
            }

            label
            {
                display:     flex;
                flex-flow:   column;
                font-weight: 300;

                &:not(:first-child)
                {
                    margin-top: 38px;
                }

                &:not(:last-child)
                {
                    margin-bottom: 24px;
                }

                span
                {
                    display:         inline-flex;
                    flex-flow:       row wrap;
                    justify-content: space-between;
                    margin-bottom:   12px;

                    a
                    {
                        margin-left: auto;
                        white-space: nowrap;
                    }
                }

                input
                {
                    padding:       11px 18px;
                    border:        1px solid $doveGray;
                    border-radius: 10px;
                    font-size:     20px;
                    font-weight:   bold;

                    @include placeholderColor($silver);
                }
            }

            button
            {
                width:         100%;
                border-radius: 10px;
                padding:       16px 0;
                margin-top:    33px;
                font-weight:   bold;
                font-size:     16px;

                @include scaleTransformEffect(1.05, 1.1);
            }

            .alertBoxWrapper
            {
                margin-top:    -50px;
                margin-bottom: -50px;
            }
        }
    }

    .loginScreenStyle
    {
        flex:                $largeAngle;
        background-size:     cover;
        background-image:    url('../../assets/images/login.jpg');
        background-position: center;
    }
}
