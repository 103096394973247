//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.datePickerControl
{
    color:           $white;
    font-family:     $fontSourceGeneralBody;
    border-radius:   12px;
    height:          52px;
    display:         flex;
    align-items:     center;
    justify-content: space-between;
    position:        relative;

    input[type="date"]
    {
        min-width: calc(100% - 20px);

        &::-webkit-inner-spin-button,
        &::-webkit-calendar-picker-indicator
        {
            display:    none;
            appearance: none;
        }

        @media (min-width: $mobileBreakpoint)
        {
            min-width: auto;
        }
    }

    > span
    {
        margin-left:  auto;
        margin-right: auto;
    }

    .indicatorContainer
    {
        padding-right: 24px;
        position:      absolute;
        right:         0;

        .wrappedIcon
        {
            width:      20px;
            transform:  rotate(90deg);
            color:      $black;
            background: $white;
        }
    }
}

.overlayContainer
{
    position:        absolute;
    z-index:         100;
    top:             0;
    left:            0;
    width:           100%;
    height:          100%;
    opacity:         0;
    pointer-events:  none;
    display:         flex;
    align-items:     center;
    justify-content: center;
    backdrop-filter: blur(10px);

    &.overlayContainerOpen
    {
        opacity:        1;
        pointer-events: all;
    }

    .overlayContainerInner
    {
        width:     370px;
        max-width: 100%;
    }
}
