//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.content
{
    width: 100%;

    @media (min-width: $mobileBreakpoint)
    {
        flex: 1 0 50%;
    }

    h1
    {
        text-align:    center;
        margin-bottom: 60px;

        @media (min-width: $mobileBreakpoint)
        {
            text-align:    left;
            margin-bottom: 40px;
        }
    }

    .disabledButtonWrapper
    {
        position: relative;
        cursor:   not-allowed;
        display:  flex;

        > *
        {
            flex: 1;
        }
    }

    .buttonsMobile
    {
        display:        flex;
        flex-direction: column;
        gap:            40px;

        @media (min-width: $screen-sm)
        {
            flex-direction: row;
        }

        @media (min-width: $mobileBreakpoint)
        {
            display: none;
        }

        > *
        {
            flex: 1;
        }
    }

    .buttonsDesktop
    {
        display: none;

        @media (min-width: $mobileBreakpoint)
        {
            display:   flex;
            flex-flow: row wrap;
            gap:       15px;
        }

        > *
        {
            flex: 1;
        }
    }

    .planStatusMobile
    {
        margin-top: 50px;

        @media (min-width: $mobileBreakpoint)
        {
            display: none;
        }
    }

    .homeArchive
    {
        margin-top: 40px;

        h1
        {
            display:     block;
            text-align:  center;
            white-space: nowrap;

            @media (min-width: $mobileBreakpoint)
            {
                text-align: left;
            }
        }

        .archiveTitle
        {
            display:        flex;
            flex-direction: column;

            > div
            {
                width:         100%;
                margin-bottom: 30px;
            }

            @media (min-width: $screen-xs)
            {
                flex-flow: row nowrap;

                > div
                {
                    max-width:   300px;
                    margin-left: 30px;
                }
            }
        }

        .articleList
        {
            display:   flex;
            flex-flow: row wrap;
            gap:       15px;

            > *
            {
                flex:       1 0 30%;
                max-height: 245px;
                min-width:  235px;
                overflow:   hidden;
            }
        }
    }

    .emptyWordsIconWrapper
    {
        width:  160px;
        height: 160px;
        margin: 0 auto;

        @include svgColor($red);

        @media (min-width: $mobileBreakpoint)
        {
            margin: 0 0;
        }
    }

    .emptyWordsButtonWrapper
    {
        margin-top: 40px;

        button
        {
            margin: 0 auto;
        }

        button:not(:last-child)
        {
            margin-bottom: 20px;
        }

        @media (min-width: $mobileBreakpoint)
        {
            button
            {
                margin: 0;
            }
        }
    }
}

.leftPanel
{
    width:   100%;
    display: none;

    @media (min-width: $mobileBreakpoint)
    {
        max-width: 400px;
        display:   block;
    }

    .planStatus
    {
        position: relative;
    }
}
