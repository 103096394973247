//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.assistantTipWrapper
{
    position:        relative;
    display:         flex;
    justify-content: center;

    .assistantTipTriangle
    {
        display:  flex;
        position: absolute;
        top:      -15px;
        color:    $taraweraBlue;
        width:    25px;
        height:   20px;

        @include svgColor($taraweraBlue);
    }
}

.assistantTip
{
    $size:            84px;

    border-radius:    10pt;
    background-color: $taraweraBlue;
    min-height:       $size;
    justify-content:  space-between;
    max-width:        352pt;
    position:         relative;
    display:          block;
    overflow:         hidden;

    .textWrapper
    {
        align-items:   center;
        height:        100%;
        font-weight:   $fontWeightLight;
        padding:       15px;
        display:       block;
        width:         100%;
        margin-bottom: 20px;
    }

    .fireFighterImage
    {
        width:           20%;
        display:         flex;
        align-self:      end;
        justify-content: end;
        padding-left:    12px;
        margin-top:      20px;
        float:           left;
        height:          100%;
        align-items:     end;
        shape-outside:   inset(calc(100% - 100px) 0 0);

        > svg
        {
            height: 120px;
        }
    }
}
