//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.listItem
{
    position:        relative;
    display:         flex;
    flex-direction:  column;
    justify-content: space-between;
    align-items:     stretch;
    border-radius:   10px;
    box-shadow:      0 2px 7px 0 $black25;
}

.header
{
    display:         flex;
    flex-direction:  row;
    justify-content: space-between;
    align-items:     center;
    padding:         20px;
    height:          100px;
    position:        relative;
    border-radius:   0.5rem 0.5rem 0 0;
}

.loadingHeader
{
    animation:  loader 2.5s ease-in-out infinite;
    background: $loadingGray;
    position:   absolute;
    height:     100%;
    bottom:     0;
}

.titleGroup
{
    display:        flex;
    flex-direction: column;
    color:          $blue;
    height:         100%;

    div
    {
        overflow:           hidden;
        display:            -webkit-box;
        line-clamp:         2;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}

.title
{
    font-size:   20px;
    font-weight: bold;
}

.content
{
    font-size:  15px;
    padding:    20px;
    max-height: 100%;
    overflow:   hidden;
}

@keyframes loader
{
    0%
    {
        width: 0;
        left:  0;
        right: 0;
    }

    50%
    {
        width: 100%;
        left:  0;
        right: 0;
    }

    99%
    {
        width: 0;
        left:  100%;
        right: 0;
    }
}
