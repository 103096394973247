//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.editUnitOverlayContent
{
    display:     flex;
    flex-flow:   column;
    align-items: center;
    gap:         30px;
    width:       350px;
    max-width:   100%;
    margin-top:  45px;

    .submitButtonWrapper
    {
        button
        {
            width:            fit-content;
            font-size:        20px;
            font-weight:      bold;
            border-radius:    10px;
            background-color: $forestGreen;
            margin-bottom:    30px;
            box-shadow:       0 5px 10px $black20;

            @include scaleTransformEffect(1.05, 1.1);
        }
    }

    .editUnitAlertBoxWrapper
    {
        width:  350px;
        margin: 0 auto;
    }
}
