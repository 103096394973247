//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.loadingOverlay
{
    display:    none;
    top:        0;
    left:       0;
    background: $white80;
    z-index:    $zIndexLoadingOverlay;
    position:   fixed;
    width:      100%;
    height:     100%;
    overflow:   hidden;
    color:      $white;

    .loadingOverlayContent
    {
        $size:            875px;
        $maxContentWith:  350px;

        width:            $size;
        height:           $size;
        margin:           auto;
        background-color: $planRed;
        border-radius:    10px;
        display:          flex;
        flex-direction:   column;
        align-items:      center;
        position:         relative;

        .loadingOverlayContentTitle
        {
            font-size:   40px;
            font-weight: $fontWeightBold;
            text-align:  center;
            margin-top:  90px;
            max-width:   $maxContentWith;
        }

        .loadingOverlayContentText
        {
            font-size:   20px;
            font-weight: $fontWeightLight;
            text-align:  center;
            margin-top:  38px;
            max-width:   $maxContentWith;
        }

        .loadingOverlayContentFirefighter
        {
            position: absolute;
            bottom:   0;
            right:    0;
            width:    450px;
            height:   642px;
            overflow: hidden;
        }

        .loadingOverlayContentCloseButton
        {
            $size:    54px;
            $padding: 24px;

            color:    $white;
            position: absolute;
            height:   $size;
            width:    $size;
            top:      $padding;
            right:    $padding;
        }

        .loadingOverlayContentLoadingWrapper
        {
            position:        absolute;
            width:           100%;
            height:          100%;
            display:         flex;
            justify-content: center;
            align-items:     center;

            .loadingOverlayContentLoading
            {
                position:  absolute;
                width:     137px;
                height:    137px;
                animation: rotate 8s linear infinite;
            }
        }
    }
}

@keyframes rotate
{
    from
    {
        transform: rotate(0deg);
    }

    to
    {
        transform: rotate(360deg);
    }
}

.loadingOverlayVisible
{
    display: flex;
}
