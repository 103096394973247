//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

$blue:            #1b4e6a;
$gray:            #cccccc;
$red:             #ff0000;
$darkRed:         #d83643;
$red80:           rgba(255 0 0 / 80%);
$black20:         rgba(0 0 0 / 20%);
$white80:         rgba(255 255 255 / 80%);

$taraweraBlue:    #094564;
$screaminGreen:   #4ded66;
$galleryGray:     #ebebeb;
$white:           #ffffff;
$white20:         #ffffff33;
$white60:         #ffffff99;
$white100:        #ffffff00;
$doveGray:        #707070;
$alto:            #dbdbdb;
$orientBlue:      #005779;
$orientBlue83:    #005779b2;
$mineShaftGrey:   #2e2e2e;
$black:           #000000;
$black75:         #000000be;
$black50:         #00000090;
$black30:         #00000030;
$black25:         #00000040;
$black10:         #00000019;
$silver:          #c7c7c7;
$loadingGray:     #bbbbbb;
$silverChalice:   #9d9d9d;
$lightSilver:     #f7f7f7;
$silverChalice50: #9d9d9d65;
$monzaRed:        #df0020;
$brightRed:       #cb0505;
$crimsonRed:      #ca0f2b;
$forestGreen:     #1eba30;
$funBlue:         #1a6eac;
$regalBlue:       #004667;
$primaryColor:    $blue;
$transparent:     rgba(0 0 0 / 0%);
$planRed:         #f12c4f;
$labelRed:        #dd3d3b;

$planYellow:      #d8f02d;
$planGreen:       #37ee2c;
$brightGreen:     #3ac606;
$calmGreen:       #42ae5c;
$forestGreen:     #1eba30;
$lightGreen:      #ccf8d7;
$regalBlue:       #004667;

:export
{
    colorBlue:        $blue;
    colorGray:        $gray;
    colorRed:         $red;
    colorDarkRed:     $darkRed;
    colorRed80:       $red80;
    colorWhite:       $white;
    colorWhite20:     $white20;
    colorWhite80:     $white80;
    colorTransparent: $transparent;
    colorPlanRed:     $planRed;
    colorPlanYellow:  $planYellow;
    colorForestGreen: $forestGreen;
    colorCrimsonRed:  $crimsonRed;
    colorPlanGreen:   $planGreen;
    colorRegalBlue:   $regalBlue;
    colorBlack:       $black;
}
