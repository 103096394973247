//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.checkBoxWrapper
{
    display:        flex;
    flex-direction: column;
}

.checkBoxWrapperNoMarginBottom
{
    margin-bottom: 0;
}

.checkBoxWrapperClickArea
{
    cursor:         pointer;
    display:        flex;
    flex-direction: row;
}

.checkBoxWrapperLabel
{
    color:       $gray;
    font-size:   13px;
    font-weight: $fontWeightRegular;
    margin:      auto 0 auto 14px;
    user-select: none;

    &.checkBoxLabelSmall
    {
        font-size: 12px;
    }

    &.checkBoxLabelLarge
    {
        font-size: 16px;
    }
}

.checkBox
{
    border:        1px solid $gray;
    border-radius: 4px;

    &.checkBoxSmall
    {
        width:  15px;
        height: 15px;
    }

    &.checkBoxLarge
    {
        width:  25px;
        height: 25px;
    }
}

.checkBoxChecked
{
    span
    {
        height: 100%;
    }

    svg
    {
        @include svgColor($gray);

        height: 100%;
        width:  100%;
        margin: 0 0 0 0;
    }
}
