//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

// stylelint-disable-next-line primer/no-unused-vars
$fontsPath:             '../assets/fonts/';

$fontSourceCourierNew:  source-code-pro,
                        menlo,
                        monaco,
                        consolas,
                        'Courier New',
                        monospace;
$fontSourceGeneralBody: 'Poppins',
                        'Helvetica Neue',
                        monospace,
                        -apple-system,
                        blinkmacsystemfont,
                        'Segoe UI',
                        'Oxygen',
                        'Ubuntu',
                        'Cantarell',
                        'Fira Sans',
                        'Droid Sans',
                        sans-serif;

@font-face
{
    font-family:  Poppins;
    src:          url('#{$fontsPath}Poppins/Poppins-Regular.woff2') format('woff2'),
                  url('#{$fontsPath}Poppins/Poppins-Regular.woff') format('woff'),
                  url('#{$fontsPath}Poppins/Poppins-Regular.ttf') format('ttf');
    font-weight:  normal;
    font-style:   normal;
    font-display: swap;
}

@font-face
{
    font-family:  Poppins;
    src:          url('#{$fontsPath}Poppins/Poppins-Light.woff2') format('woff2'),
                  url('#{$fontsPath}Poppins/Poppins-Light.woff') format('woff'),
                  url('#{$fontsPath}Poppins/Poppins-Light.ttf') format('ttf');
    font-weight:  300;
    font-style:   normal;
    font-display: swap;
}

@font-face
{
    font-family:  Poppins;
    src:          url('#{$fontsPath}Poppins/Poppins-LightItalic.woff2') format('woff2'),
                  url('#{$fontsPath}Poppins/Poppins-LightItalic.woff') format('woff'),
                  url('#{$fontsPath}Poppins/Poppins-LightItalic.ttf') format('ttf');
    font-weight:  300;
    font-style:   italic;
    font-display: swap;
}

@font-face
{
    font-family:  Poppins;
    src:          url('#{$fontsPath}Poppins/Poppins-BoldItalic.woff2') format('woff2'),
                  url('#{$fontsPath}Poppins/Poppins-BoldItalic.woff') format('woff'),
                  url('#{$fontsPath}Poppins/Poppins-BoldItalic.ttf') format('ttf');
    font-weight:  bold;
    font-style:   italic;
    font-display: swap;
}

@font-face
{
    font-family:  Poppins;
    src:          url('#{$fontsPath}Poppins/Poppins-Bold.woff2') format('woff2'),
                  url('#{$fontsPath}Poppins/Poppins-Bold.woff') format('woff'),
                  url('#{$fontsPath}Poppins/Poppins-Bold.ttf') format('ttf');
    font-weight:  bold;
    font-style:   normal;
    font-display: swap;
}

:export
{
    fontsourcecouriernew:  $fontSourceCourierNew;
    fontsourcegeneralbody: $fontSourceGeneralBody;
}
